<template>
  <div>
    <base-list-view
      :dataSource="dataSource"
      :headers="headers"
      :loading="loading"
      @onRefresh="init"
      @onDelete="del"
      pageEditName="KnowledgeBaseBaseCourseEdit"
      title="Базовые курсы"
      label-text-btn-create="Создать базовый курс"
      label-text-search="Поиск курса"
      id="knowledge-base__courses"
      showCopy
      sort-by="Name"
      :exclude-settings-actions="['group']"
    >
      <template slot="item.Name" slot-scope="{ item }">
        <span>{{ item.Name }}</span>
      </template>
      <template slot="item.Subject" slot-scope="{ item }">
        {{ item && item.Subject ? item.Subject.Name : "" }}
      </template>
    </base-list-view>
  </div>
</template>
<script>
import KnowledgeBase from "@/services/KnowledgeBaseService";
import BaseListView from "../../layouts/BaseListView.vue";
import ListEditMixin from "@/mixins/ListEditMixin";
export default {
  name: "view-knowledgebase",
  components: {
    BaseListView,
  },
  mixins: [ListEditMixin],
  data() {
    return {
      apiService: KnowledgeBase.courseTemplate,
      headers: [
        { text: "Название курса", value: "Name", notGroup: true },
        {
          text: "Тематика / Вид учебного материала",
          value: "Subject",
          dataType: "object",
          displayText: (e) => e.Name,
        },
        { text: "Авторы", value: "Authors" },
      ],
      loading: true,
      dataSource: [],
    };
  },

  async mounted() {
    this.init();
  },
  methods: {
    DataFromServerNormalize(data) {
      data.forEach((item) => {
        item.Authors = item.Authors.map((a) =>
          this.$options.filters.PersonShortName(a)
        ).join(", ");
      });

      return data;
    },
  },
};
</script>
